<div class="modal-overlay fixed w-full h-full top-0 left-0 z-[999] no-doc-scroll" *ngIf="isVisible && authHelper.userRetrievalDone && !authHelper.user"> 
    <div class="backdrop-blur-sm bg-black/50 w-full h-full"  (click)="closePopup()">
	</div>
    <div
        class="modal-dialog flex max-h-[80%] w-full max-w-[600px] sm:w-[600px] bottom-0 sm:bottom-auto sm:top-1/2 left-1/2 -translate-x-1/2 sm:-translate-y-1/2 absolute">
        <div class="bg-white rounded-lg overflow-auto p-6 w-full exit-intent-popup-form">
            <div class="flex items-center justify-center">
                <img src="https://imgix.cosmicjs.com/9a4e0b30-d1f6-11ee-babb-b99b48a02ac8-dimers-pro-logo.svg" alt="dimers pro icon" width="70" height="70" loading="lazy">
            </div>
            <div class="text-lg text-gray-900 font-semibold text-center">
                Winners Don't Quit!
            </div>
            <div class="text-base text-gray-900 font-medium text-center">
                Claim Your Dimers Pro Discount Now
            </div>
            <div class="text-sm text-gray-600 font-medium text-center leading-5 mt-2">
                Stay ahead of the game with unlimited access to our full suite of picks and predictions. Drop your email to unlock your discount code.
            </div>
            <div class="mt-4">
                <div class="grid grid-cols-1" *ngIf="!formSent">
                    <input type="email" name="email" id="email"
                    [(ngModel)]="email"
                    placeholder="Email"
                    name="email"
                    ngDefaultControl
                    (keyup)="verifyForm()"
                        class="col-start-1 row-start-1 block w-full rounded-md bg-white py-1.5 pl-10 pr-3 text-base text-gray-900 outline outline-1 -outline-offset-1 outline-gray-300 placeholder:text-gray-400 focus:outline focus:outline-2 focus:-outline-offset-2 focus:outline-highlight-100 sm:pl-9 sm:text-sm/6"
                        placeholder="you@example.com">
                    <svg class="pointer-events-none col-start-1 row-start-1 ml-3 size-5 self-center text-gray-400 sm:size-4"
                        viewBox="0 0 16 16" fill="currentColor" aria-hidden="true" data-slot="icon">
                        <path
                            d="M2.5 3A1.5 1.5 0 0 0 1 4.5v.793c.026.009.051.02.076.032L7.674 8.51c.206.1.446.1.652 0l6.598-3.185A.755.755 0 0 1 15 5.293V4.5A1.5 1.5 0 0 0 13.5 3h-11Z" />
                        <path
                            d="M15 6.954 8.978 9.86a2.25 2.25 0 0 1-1.956 0L1 6.954V11.5A1.5 1.5 0 0 0 2.5 13h11a1.5 1.5 0 0 0 1.5-1.5V6.954Z" />
                    </svg>
                </div>
                <div class="send-info p-4 rounded-md border border-gray-300" *ngIf="formSent && !formError">
                    <div class="text-base text-gray-900 font-medium">
                        Subscribed successfully 
                    </div>
                    <div class="text-sm text-gray-600 font-medium">
                        Check your email to get your discount code
                    </div>
                </div>
                <!-- <button class="h-9 px-3 py-2 text-sm font-medium text-center text-white bg-highlight-100 rounded cursor-pointer hover:bg-blue-800 focus:ring-4 focus:ring-blue-300">Get discount code</button> -->
            </div>
            <div class="flex items-center justify-center mt-2" *ngIf="!formSent">
                <button (click)="sendEmail()" [class.cursor-not-allowed]="!formValid || formSending" [class.bg-highlight-50]="!formValid || formSending" class="submit-email-button text-base text-white font-medium bg-highlight-100 whitespace-nowrap hover:bg-highlight-50 shadow rounded-md border-0 ring-1 ring-inset ring-highlight-100 px-4 py-2 transition-all delay-75">
                    {{ formSending ? 'Please wait...' : 'Get discount code' }}
                </button>
            </div>
        </div>
        <div class="flex md:hidden close-button leading-none top-2 right-2 cursor-pointer absolute h-[28px] min-w-[28px] bg-black/60 rounded-full items-center justify-center" (click)="closePopup()">
            <fa-icon class="text-white text-sm" [icon]="faTimes" aria-label="Close"></fa-icon>
        </div>
        <div class="hidden cursor-pointer md:flex items-center justify-center h-[32px] min-w-[32px] bg-black/60 rounded-full ml-2 hover:bg-black" (click)="closePopup()">
            <fa-icon class="text-white" [icon]="faTimes" aria-label="Close"></fa-icon>
        </div>
    </div>
</div>