import { Component, ElementRef, Inject, OnInit, PLATFORM_ID, Renderer2, ViewChild, ViewEncapsulation } from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';
import { BlogService } from 'src/app/blog.service';
import { UserLocationData } from 'src/app/user-location-data.model';
import { faChevronDown, faChevronUp, faXmark  } from '@fortawesome/pro-solid-svg-icons';
import { GoogleTagManagerService } from 'src/app/angular-google-tag-manager.service';
import { AsyncPipe, DecimalPipe, DOCUMENT, isPlatformBrowser, LowerCasePipe, NgFor, NgIf } from '@angular/common';
import { MatchService } from 'src/app/match.service';
import { AuthHelperService } from 'src/app/auth-helper.service';
import { BetService } from 'src/app/bet.service';
import { GeneralService } from 'src/app/general.service';
import { LocalisationService } from 'src/app/localisation.service';
import { SportDataService } from 'src/app/sport-data.service';
import { Match } from 'src/app/match.model';
import { differenceInHours, parseJSON, compareAsc } from 'date-fns';
import { SPORTS } from 'src/app/sports';
import { MatchBet } from 'src/app/match-bet.model';
import { BOOKMAKERS } from 'src/app/bookmakers';
import { faChevronsUp, faChevronsDown } from "@fortawesome/pro-solid-svg-icons";
import { Observable } from 'rxjs';
import { faTimes, faArrowUpRightFromSquare } from '@fortawesome/pro-light-svg-icons';
import { BookIconPipe } from 'src/app/book-pipes/book-icon.pipe';
import { TeamIconComponent } from '../team-icon/team-icon.component';
import { FlagIconComponent } from '../flag-icon/flag-icon.component';
import { TeamIconPairComponent } from '../team-icon-pair/team-icon-pair.component';
import { FlagIconPairComponent } from '../flag-icon-pair/flag-icon-pair.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faRedoAlt } from '@fortawesome/pro-regular-svg-icons';
import { EmblaCarouselDirective } from 'embla-carousel-angular';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-mobile-sticky-banner',
  templateUrl: './mobile-sticky-banner.component.html',
  styleUrls: ['./mobile-sticky-banner.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    NgIf,
    NgFor,
    AsyncPipe,
    LowerCasePipe,
    BookIconPipe,
    DecimalPipe,
    TeamIconComponent,
    FlagIconComponent,
    TeamIconPairComponent,
    FlagIconPairComponent,
    FontAwesomeModule,
    EmblaCarouselDirective,
    RouterLink
  ],
})
export class MobileStickyBannerComponent implements OnInit {
    @ViewChild('bannerSlider', {read: EmblaCarouselDirective}) bannerSlider: EmblaCarouselDirective;
    @ViewChild('modalSlider', {read: EmblaCarouselDirective}) modalSlider: EmblaCarouselDirective;
    locationData: UserLocationData;
    stateCode:string;
    faChevronDown= faChevronDown;
    faChevronUp = faChevronUp;
    faXmark = faXmark;
    faArrowUpRightFromSquare = faArrowUpRightFromSquare;
    faRedoAlt = faRedoAlt;

    offerDropdownOpen:boolean = false;
    offerVisible = false;
    matchInfoVisible:boolean = false;
    freeMatchInfoVisible:boolean = false;

    bestBookTab:string ="";
    browserMode:boolean = false;
    freeLimit = 3;
    betTab:string="";
    currentActiveSilder:number = 0;

    valueBetsSports: Array<string> =  this.localisationService.sportPrioritySort(SPORTS).filter(s => s.valueSummaryEligible).map(s => s.code);
	valuePlays: Array<MatchBet> = [];
	valuePlaysError = false;
	valuePlaysLoading = true;
	filterBetTypes = ["line", "total", "h2h", "other"];
    filterSports = [];
    filterTimeframe = 168;
    betSort = "date";
    displayedValuePlays: Array<MatchBet> = [];
    faChevronsUp = faChevronsUp;
    faChevronsDown = faChevronsDown;
    // faCircleInfo = faCircleInfo;
    faTimes = faTimes;
    
    selectedData: any = null;
    limitedInfo:boolean = false;
    totalBets:number;
    pro:boolean = false;

    betSliderOptions = {
        loop: false,
        align: "start" as "start",
    };

    proUser$: Observable<boolean> = this.authHelper.detailedUser$.pipe(
        map((detailedUser) => {
            return !!detailedUser?.app_metadata?.dimers_pro
        }),
        shareReplay(1)
    );
    
	constructor(
        private blogService: BlogService,
        private gtmService: GoogleTagManagerService,
        @Inject(DOCUMENT) public document: Document,
        @Inject(PLATFORM_ID) platformId: string,
        public matchService: MatchService,
		private sportDataService: SportDataService,
		public generalService: GeneralService,
		public betService: BetService,
		public localisationService: LocalisationService,
		public authHelper: AuthHelperService,
        private renderer: Renderer2, 
        private el: ElementRef
	) {
        this.browserMode = isPlatformBrowser(platformId);
    }

    ngOnInit() {
        let elementShown = false;
        if (typeof document !== "undefined") {
            if (typeof IntersectionObserver !== "undefined") {
                // monitor for changes in the visibility of this component
                new IntersectionObserver((entries) => {
                entries.forEach(e => {
                    // if the element is, or becomes, visible, then load the data (and mark the flag to prevent reloading)
                    if (e.intersectionRatio > 0 && !elementShown) {
                        elementShown = true;
                        this.retrieveUpcomingGames();
                    }
                })
                }, {
                    rootMargin: "50px 0px 0px 0px",
                }).observe(this.el.nativeElement);
            } else {
                // if this browser does not support IntersectionObserver, just load the data anyway
                this.retrieveUpcomingGames();
            }
        }
    }
    
    retrieveUpcomingGames() {
        this.valuePlaysLoading = true;
		this.valuePlaysError = false;
		let bookmakerList: Array<string> = BOOKMAKERS;
        this.filterSports = this.valueBetsSports.slice();

        this.sportDataService.getUpcomingGames(this.valueBetsSports, true, true, bookmakerList, this.localisationService.getLocaleObject().sportExclusiveBookmakers)
            .subscribe((data) => {
                if (data) {
                    data.forEach((match: Match) => {
                        match.MatchData.UserDate = parseJSON(match.MatchData.Date);
                    });

                    const filteredMatches: Match[] = data
                        .filter(
                            (g) =>
                                this.valueBetsSports.includes(
                                    g.MatchData.Sport.toUpperCase()
                                ) && this.matchService.isUpcoming(g)
                        )
                        .sort((a, b) => this.matchService.gameDisplaySort(a, b));

                    this.valuePlays = filteredMatches
                        .map((match) => {
                            if (
                                !match.aggregatedBestBets ||
                                match.aggregatedBestBets.length === 0
                            ) {
                                return [];
                            }

                            const matchPlays = match.aggregatedBestBets.filter(
                                (bet) => bet.edge > 2
                            );

                            matchPlays.forEach(play => play.match = { ...match, aggregatedBestBets: [] });

                            return matchPlays;
                        })
                        .reduce((a, c) => a.concat(c), []);
                    this.setDisplayedValuePlays();
                    this.valuePlaysLoading = false;
                }
            },(err) => {
                console.error(err);
                this.valuePlaysError = true;
			});
    }
    
    setDisplayedValuePlays(): void {
		const displayableValuePlays = this.valuePlays
			.filter(p => this.fitsValuePlaysFilter(p))
			.sort((a,b) => {
				if (this.betSort === "date") {
					return compareAsc(a.match.MatchData.UserDate, b.match.MatchData.UserDate);
				}
				return b.edge - a.edge;
			});
            this.totalBets = displayableValuePlays.length - 10;
            this.displayedValuePlays = displayableValuePlays
			.slice(0, 10);

    }

    fitsValuePlaysFilter(play: MatchBet, timeframe?:number): boolean {

        // if all bookmakers is set, hide this bet if there's another one for the same match and market with a higher edge
        const similarBets = this.valuePlays.filter(p => p.match === play.match && p.type === play.type);
        if (similarBets.length > 0) {
            if (similarBets.some(otherBet => this.betOutranksBet(otherBet, play))) {
                return false;
            }
        }

		if (this.filterBetTypes.length >= 0) {
			if (!this.filterBetTypes.includes("other") && ["firstset"].includes(play.type)) {
				return false;
			}
			if (!this.filterBetTypes.includes(play.type)) {
				return false;
			}
		}

		// check timeframe
        if(!this.matchService.isUpcoming(play.match)
			|| differenceInHours(play.match.MatchData.UserDate, new Date()) > (timeframe ? timeframe : this.filterTimeframe)) {
			return false;
		}

		return true;
	}

    betOutranksBet(otherBet: MatchBet, play: MatchBet): boolean {
		if (otherBet.edge !== play.edge) {
			return otherBet.edge > play.edge;
		}
		// if it's a dead heat for best edge, only return the one whose bookmaker comes first alphabetically
		return otherBet.bookmaker > play.bookmaker;
	}

    oddsButtonClick(play: any) {
        this.betService.sharedLegalOnlineBettingData$.subscribe(data => {
            if (data) {
                this.betService.goToPromoLink(this.betService.bookmakerLink(play.bookmaker, play.linkDetails), play.bookmaker, 'quick-picks');
            }
            else {
                this.betService.setSharedModalData(true);
                this.betService.setSharedBookMakerData(this.betService.stylisedBookName(play.bookmaker));
                this.betService.setSharedOddsRedirectLinkData(this.betService.bookmakerLink(play.bookmaker, play.linkDetails));
            }
        });
    }

    toggleShow =  (matchID: string, item:any, inside:boolean, limitedInfo:boolean, index:number):void =>{
        this.limitedInfo = limitedInfo;
        const hostElement = this.el.nativeElement;
        this.renderer.setStyle(hostElement, 'z-index', '1000');
        this.matchInfoVisible = true;
        this.betTab = matchID;
        this.selectedData = item;
        this.currentActiveSilder = index;
        if(inside !== true) {
            setTimeout(() => {
                this.modalSlider.scrollTo(this.bannerSlider.emblaApi.selectedScrollSnap(), false)
            }, 500);
        }
    }

    nextBet():void {
        this.proUser$.subscribe((pro) => {
			if (pro === true ) {
                this.pro = true;
			}
		});
        if(this.currentActiveSilder +1 < this.freeLimit || this.pro === true){
            this.toggleShow('bet' + (this.currentActiveSilder +1), this.displayedValuePlays[this.currentActiveSilder+1] , true, false, (this.currentActiveSilder +1));
        }else {
            this.toggleShow('bet' + (this.currentActiveSilder+1), this.displayedValuePlays[this.currentActiveSilder+1] , true, true, (this.currentActiveSilder +1));
        }
        this.modalSlider.scrollTo(this.currentActiveSilder - 1, false);
    }

    closePopUpOffer():void {
        const hostElement = this.el.nativeElement;
        this.renderer.removeStyle(hostElement, 'z-index');

        this.betTab = null;
        this.matchInfoVisible = false;
        this.limitedInfo = false;
    }

    trackByFn(index: number, item: any): any {
		return item ? item.key : undefined;
	}

    openProModal() {
        this.closePopUpOffer();
        // Open Pro modal
        const gtmTag = {
            event: "masked-mobile-sticky-bet",
            event_category: "sub-page",
        };
      
        this.gtmService.pushTag(gtmTag)
            .catch(e => {
                console.error("Google Tag Manager Script failed: " + e)
            });
        this.blogService.setProModalData(true, true);
    }

}


