import { Component, Inject, OnInit, PLATFORM_ID, HostListener } from '@angular/core';
import { NgIf, isPlatformBrowser } from '@angular/common';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NavigationStart, Router } from '@angular/router';
import { BlogService } from '../../blog.service';
import { AuthHelperService } from '../../auth-helper.service';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-exit-intent-popup',
    standalone: true,
    imports: [NgIf, FontAwesomeModule, FormsModule, ],
    templateUrl: './exit-intent-popup.component.html',
    styleUrl: './exit-intent-popup.component.scss'
})
export class ExitIntentPopupComponent implements OnInit {
    browserMode: boolean;
    faTimes = faTimes;
    isVisible = false;

    formSending = false;
	formSent = false;
	formError = false;
	formValid = false;
	email = '';

    constructor(
        private router: Router,
        private blogService: BlogService,
        @Inject(PLATFORM_ID) platformId: string,
        public authHelper: AuthHelperService,
    ) {
        this.browserMode = isPlatformBrowser(platformId);
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart && event.navigationTrigger === 'popstate') {
                this.displayPopup();
            }
          });
    }

    ngOnInit() {
        const localPopupShown = localStorage.getItem('popupShown');
        const sessionPopupShown = sessionStorage.getItem('popupShown');
        if (!localPopupShown && !sessionPopupShown) {
          this.isVisible = false; // Initially hidden
        }
    }

    displayPopup() {
        if (!localStorage.getItem('popupShown')&&!sessionStorage.getItem('popupShown')) {
          this.isVisible = true; // Show the popup
        }
    }

    @HostListener('document:mousemove', ['$event'])
    onMouseMove(event: MouseEvent) {
      if (event.clientY < 10) { // Mouse near the top of the window
            this.displayPopup();
      }
    }

    @HostListener('window:blur', [])
    onWindowBlur() {
        this.displayPopup();
    }

    @HostListener('document:visibilitychange', [])
    onVisibilityChange() {
        if (document.hidden) {
            this.displayPopup();
        }
    }

    closePopup() {
        this.isVisible = false;
        sessionStorage.setItem('popupShown', 'true');
    }

    verifyForm(): void {
		if (!this.email) {
			this.formValid = false;
		} else if (!/.+@.+\..+/.test(this.email)) {
			this.formValid = false;
		} else {
			this.formValid = true;
		}
	}

    sendEmail(): void {
		if (this.formValid) {
			this.formSending = true;
			this.formSent = false;
			this.formError = false;

			this.blogService.discountCodeEmail(this.email).subscribe(
				(_) => {
					this.formSent = true;
					this.formSending = false;
                    localStorage.setItem('popupShown', 'true');
				},
				() => {
					this.formError = true;
					this.formSending = false;
				}
			);
		}
	}
}
