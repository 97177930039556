<div class="nav-section">
    <!-- <div class="menu-button" (click)="toggleMobileNav()">
        <fa-icon [icon]="faBars"></fa-icon>
    </div> -->
    <div class="mobile-logo">
        <a routerLink="/">
            <img alt="Dimers" loading="lazy" src="https://cdn.ciphersports.io/images/dimers-site/dimers-yellow.svg"
                height="68" width="300">
        </a>
    </div>
    <div class="right-side">
        <!-- <a routerLink="/dimers-pro" class="pro-button">
            <img
                class="pro-logo"
                src="https://cdn.ciphersports.io/images/dimers-site/dimers-pro-icon.svg"
                alt="Dimers Pro"
            >
        </a> -->
        <!-- <div class="bg-white p-2 flex items-center justify-center relative rounded-full h-8 w-8 cursor-pointer mr-2" (click)="openTagsModal()">
            <fa-icon [icon]="faBell" class="text-highlight-100 text-lg"></fa-icon>
            <span *ngIf="dotVisible" class="rounded-full bg-highlight-100 h-2 w-2 absolute top-0.5 right-0"></span>
        </div> -->
        <ng-container *ngIf="authHelper.userRetrievalDone && authHelper.user">
            <!-- <div class="user-name">
                <div class="user-icon-ctr" (click)="toggleAccountDropdown()">
                    <img class="user-icon" [src]="authHelper.user.picture">
                </div>
            </div> -->
            <button class="user-dropdown rounded shadow px-2 py-2 bg-white flex" (click)="toggleAccountDropdown()">
                <img *ngIf="(proUser$ | async) === true" src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg" class="h-6 w-6 mr-2" alt="dimers pro icon">
                <img *ngIf="(proUser$ | async) === false"  src="https://imgix.cosmicjs.com/e87d0d90-df63-11ee-a698-6f33e3e4a628-Dimers-Round-Logo-1.svg" class="h-6 w-6 mr-2" alt="dimers basic icon">
                <fa-icon [icon]="faChevronDown" aria-hidden="true"></fa-icon>
            </button>
        </ng-container>
        <ng-container *ngIf="authHelper.userRetrievalDone && !authHelper.user">
            <div  (click)="authHelper.goToLoginWithState()">
                <button class="login-button"> Log In</button>
            </div>
            <div (click)="authHelper.goToLoginWithState(true,'/my-account')" class="ml-2">
                <button class="signup-button">Join For Free</button>
            </div>
        </ng-container>
</div>
   <!-- <div class="mobile-navigation" [class.visible]="mobileNavVisible">
        <a routerLink="/live-now" class="nav-item link">
            <span>
                Live Now
                <div class="live-beacon" *ngIf="this.liveLightVisible"></div>
            </span>
        </a>
        <div class="nav-item dropdown"
            (click)="toggleSportsDropdown()">
            <span>
                Sports Predictions
            </span>
            <fa-icon class="right-arrow" [icon]="faChevronRight"></fa-icon>
        </div>
        <a routerLink="/best-bets" class="nav-item link">Best Bets</a>

        <div class="nav-item dropdown"
            (click)="toggleBooksDropdown()">
            <span>
                Betting Sites
            </span>
            <fa-icon class="right-arrow" [icon]="faChevronRight"></fa-icon>

        </div>


        <div class="nav-item dropdown"
            (click)="toggleMoreDropdown()">
            <span>
                More
            </span>
            <fa-icon class="right-arrow" [icon]="faChevronRight"></fa-icon>

        </div>

        <div class="nav-item" *ngIf="authHelper.userRetrievalDone && !authHelper.user"
            (click)="authHelper.goToLoginWithState()">
            <span>
                Log In
            </span>

        </div>

        <div class="nav-item dropdown" *ngIf="authHelper.userRetrievalDone && authHelper.user"
            (click)="toggleAccountDropdown()">
            <span>
                Hi {{ authHelper.user.user_metadata && authHelper.user.user_metadata.screenname ? authHelper.user.user_metadata.screenname : authHelper.user.name }}
            </span>
            <fa-icon class="right-arrow" [icon]="faChevronRight"></fa-icon>

        </div>

        <div class="mobile-search">
            <input type="text" [(ngModel)]="searchText" class="search-input"
                placeholder="Search..."
                (keyup.enter)="submitSearch()">
            <fa-icon [icon]="faSearch" (click)="submitSearch()"></fa-icon>
        </div>
        <div class="menu-backdrop" [class.disabled]="!sportsDropdownOpen" (click)="hideSportsDropdown()"></div>
        <div class="menu-backdrop" [class.disabled]="!moreDropdownOpen" (click)="hideMoreDropdown()"></div>
        <div class="menu-backdrop" [class.disabled]="!booksDropdownOpen" (click)="hideBooksDropdown()"></div>
        <div class="menu-backdrop" [class.disabled]="!accountDropdownOpen" (click)="hideAccountDropdown()"></div>
    </div> -->
</div>
<!-- 
<div *ngIf="authHelper.userRetrievalDone && !authHelper.user" class="nav-sub-section bg-AIDimers-grey flex justify-center px-5 py-3 sm:hidden">
<button (click)="authHelper.goToLoginWithState(true, '/my-account')" class="mobile-join-for-free-btn w-full">Join for free</button>
</div> -->

<!-- <div *ngIf="!authHelper.userRetrievalDone" class="nav-sub-section bg-AIDimers-grey flex justify-center px-5 py-3 sm:hidden h-[64px]">
<div role="status">
    <svg aria-hidden="true" class="inline w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"/>
        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill"/>
    </svg>
    <span class="sr-only">Loading...</span>
</div>
</div>

<div *ngIf="authHelper.userRetrievalDone && authHelper.user && showNavBtn" class="nav-sub-section bg-AIDimers-grey flex justify-center px-5 py-3 sm:hidden">    
    <button *ngIf="(proUser$ | async) === false"  class="pro-button w-full" (click)="openProModal()">
        <img alt="pro-logo" src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg" class="h-6 w-6 mr-2"> 
        Try Dimers Pro
    </button>
    <button  *ngIf="(proUser$ | async) === true" class="pro-button w-full"  (click)="selectTab('dashboard')" routerLink="/my-account">
        <img alt="pro-logo" src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg" class="h-6 w-6 mr-2"> 
        Explore Pro Features
    </button>
</div> -->

<div class="fixed-footer shadow-md">
    <div class="footer-link" (click)="toggleNavSportsSidebar();">
        <img *ngIf="!sportsDropdownOpen" class="footer-icon" alt="" loading="lazy"
            src="https://imgix.cosmicjs.com/353c9e10-37fe-11ee-8ce3-27470be6bb93-Sports-OnDark.svg" height="1"
            width="1">
        <img *ngIf="sportsDropdownOpen" class="footer-icon" alt="" loading="lazy"
            src="https://imgix.cosmicjs.com/fb69a650-37fe-11ee-8ce3-27470be6bb93-Sports-Active.svg" height="1"
            width="1">
        Sports
    </div>
    <div class="footer-link" routerLink="/best-bets"
        (click)="sendTapTag('Picks');hideAllOpenMenuLinks();">
        <img class="footer-icon" alt="" loading="lazy"
            src="https://imgix.cosmicjs.com/356ada00-37fe-11ee-8ce3-27470be6bb93-QuickPicks-OnDark.svg" height="1"
            width="1">
        Best Bets
    </div>
    <!-- <div class="footer-link" routerLink="/live-now" (click)="sendTapTag('Live Now')">
        <img class="footer-icon" alt="" loading="lazy"
            src="https://cdn.ciphersports.io/images/dimers-site/nav-icon/LiveNow_Yellow.svg"
            height="1" width="1"
        >
        Live Now
    </div> -->
    <!-- <div class="footer-link" routerLink="/prop-bets"
        (click)="sendTapTag('Props'); hideAllOpenMenuLinks();">
        <img class="footer-icon" alt="" loading="lazy"
            src="https://imgix.cosmicjs.com/353bdac0-37fe-11ee-8ce3-27470be6bb93-Props-OnDark.svg" height="1" width="1">
        Props
    </div> -->
    <div class="footer-link" routerLink="/parlay-picker"
        (click)="sendTapTag('Parlays');hideAllOpenMenuLinks();">
        <img class="footer-icon" alt="" loading="lazy"
            src="https://imgix.cosmicjs.com/4340c0a0-58eb-11ee-94c0-5f69598aa628-ParlayPicker-OnDark.svg?auto=format,compress&w=40" height="1"
            width="1">
        Parlays
    </div>
    <!-- <div class="footer-link" routerLink="/super-bowl" (click)="sendTapTag('Super Bowl')">
        <img class="footer-icon" alt="" loading="lazy"
            src="https://cdn.ciphersports.io/assets/superbowl-big-game.svg"
            height="1" width="1"
        >
        SB LVII
    </div> -->
    <div class="footer-link" (click)="toggleNavProSidebar();">
        <img class="footer-icon" alt="" loading="lazy"
            src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg" height="1"
            width="1">
        Pro
    </div>
    <div class="footer-link" (click)="toggleNavMoreSidebar();">
        <img *ngIf="!moreDropdownOpen" class="footer-icon" alt="" loading="lazy"
            src="https://imgix.cosmicjs.com/353af060-37fe-11ee-8ce3-27470be6bb93-More-OnDark.svg" height="1" width="1">
        <img *ngIf="moreDropdownOpen" class="footer-icon" alt="" loading="lazy"
            src="https://imgix.cosmicjs.com/fb6894e0-37fe-11ee-8ce3-27470be6bb93-More-Active.svg" height="1" width="1">
        More
    </div>
</div>
<!-- <div class="slide-in-menu sport-menu" [class.onScreen]="sportsDropdownOpen">
    <div class="link-list">
        <a class="link"
            *ngFor="let sport of dropdownSports"
            (click)="toggleSportsDropdown(); sendTapTag(sport.shortName)"
            [routerLink]="sport.navigablePages[0].path"
            [queryParams]="sport.navigablePages[0].queryParams">
            <app-sport-icon [code]="sport.code" colour="white"></app-sport-icon>
            <span>{{ sport.shortName }}</span>
        </a>
        <a class="link"
            *ngFor="let sport of otherSports"
            (click)="toggleSportsDropdown(); sendTapTag(sport.shortName)"
            [routerLink]="sport.navigablePages[0].path"
            [queryParams]="sport.navigablePages[0].queryParams">
            <app-sport-icon [code]="sport.code" colour="white"></app-sport-icon>
            <span>{{ sport.shortName }}</span>
        </a>
    </div>
</div> -->
<div class="slide-in-menu about-menu" [class.onScreen]="accountDropdownOpen">
    <div class="back-button" (click)="hideAccountDropdown()">
        <fa-icon class="left-arrow" [icon]="faChevronDown"></fa-icon>
    </div>
    <div class="link-list">
        <ng-container *ngIf="authHelper.userRetrievalDone && authHelper.user; else loggedOut">
        <a class="link" (click)="hideAccountDropdown();selectTab('dashboard')" routerLink="/my-account">
                My Dimers
            </a>
            <a class="link" (click)="hideAccountDropdown();selectTab('details')"  routerLink="/my-account">
                Details
            </a>
            <a class="link" (click)="hideAccountDropdown();selectTab('subscription')" routerLink="/my-account">
                Subscription
            </a>
            <a class="link" (click)="hideAccountDropdown();selectTab('preferences')"  routerLink="/my-account">
                Preferences
            </a>
            <a *ngIf="(affiliate$ | async) === true" class="link" (click)="hideAccountDropdown()" href="https://dimers.promotekit.com/" target="_blank">
                Affiliate Dashboard
            </a>
            <span class="link" (click)="authHelper.logout({ returnTo: document.location.origin })">
                Log Out
            </span>
        </ng-container>
        <ng-template #loggedOut>
            <span class="link" (click)="authHelper.goToLoginWithState()">
                Log In
            </span>
            <span class="link" (click)="authHelper.goToLoginWithState(true,'/my-account')">
                Sign Up
            </span>
        </ng-template>
        <!-- <a class="link" (click)="hideAccountDropdown()" routerLink="/bet-center">
            Bet Center
        </a> -->
    </div>
</div>

<div class="nav-sports-sidebar overflow-y-auto" [ngClass]="this.navSportsSidebar ? 'show':'hide'">
    <ul class="list-none m-0">
        <li *ngFor="let sport of dropdownSports" class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                (click)="toggleNavSportsSidebar(); sendTapTag(sport.shortName)"
                [routerLink]="sport.navigablePages[0].path" [queryParams]="sport.navigablePages[0].queryParams">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <app-sport-icon *ngIf="navInteracted" [code]="sport.code" colour="white" class="w-5 h-5"></app-sport-icon>
                </div>
                <span>{{ sport.shortName }}</span>
            </a>
        </li>
        <li *ngFor="let sport of otherSports" class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                (click)="toggleNavSportsSidebar(); sendTapTag(sport.shortName)"
                [routerLink]="sport.navigablePages[0].path" [queryParams]="sport.navigablePages[0].queryParams">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <app-sport-icon *ngIf="navInteracted" [code]="sport.code" colour="white" class="w-5 h-5"></app-sport-icon>
                </div>
                <span>{{ sport.shortName }}</span>
            </a>
        </li>

    </ul>
</div>

<div class="nav-more-sidebar overflow-y-auto" [ngClass]="this.navMoreSidebar ? 'show':'hide'">
    <ul class="list-none m-0">
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center text-sm text-white py-2 px-4 h-full" routerLink="/live-now"
                (click)="toggleNavMoreSidebar(); sendTapTag('Live Now')">
                <div class="flex gap-2 items-center">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            src="https://imgix.cosmicjs.com/544b7150-58fb-11ee-94c0-5f69598aa628-LiveNow-OnDark.svg?auto=format,compress&w=20"
                            width="20" height="20" alt="icon" loading="lazy">
                    </div>
                    Live Scores
                </div>
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center text-sm text-white py-2 px-4 h-full" routerLink="/news"
                (click)="toggleNavMoreSidebar(); sendTapTag('News')">
                <div class="flex gap-2 items-center">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            src="https://imgix.cosmicjs.com/f8f63920-220d-11ef-9fc0-dbae8bde9933-News-OnDark.svg?auto=format,compress&w=20"
                            width="20" height="20" alt="icon" loading="lazy">
                    </div>
                    News
                </div>
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center text-sm text-white py-2 px-4 h-full"
                href="/sports-betting-101" (click)="toggleNavMoreSidebar(); sendTapTag('Betting Explained')">
                <div class="flex gap-2 items-center">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            src="https://imgix.cosmicjs.com/432c2730-58eb-11ee-94c0-5f69598aa628-BettingExplained-OnDark.svg?auto=format,compress&w=20"
                            width="20" height="20" alt="icon" loading="lazy">
                    </div>
                    Betting Explained
                </div>
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center text-sm text-white py-2 px-4 h-full"
                routerLink="/business" (click)="toggleNavMoreSidebar(); sendTapTag('Dimers For Business')">
                <div class="flex gap-2 items-center">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            src="https://imgix.cosmicjs.com/839aa250-9320-11ee-8a8d-abf95e574482-business-icon.svg?auto=format,compress&w=20"
                            width="20" height="20" alt="icon" loading="lazy">
                    </div>
                    Dimers For Business
                </div>
            </a>
        </li>
        <!-- <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center text-sm text-white py-2 px-4 h-full" routerLink="/podcasts"
                (click)="toggleNavMoreSidebar(); sendTapTag('Podcasts')">
                <div class="flex gap-2 items-center">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            src="https://imgix.cosmicjs.com/43415ce0-58eb-11ee-94c0-5f69598aa628-Podcasts-OnDark.svg?auto=format,compress&w=20"
                            width="20" height="20" alt="icon" loading="lazy">
                    </div>
                    Podcasts
                </div>
            </a>
        </li> -->
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center text-sm text-white py-2 px-4 h-full"
                routerLink="/responsible-gambling" (click)="toggleNavMoreSidebar(); sendTapTag('Gamble Responsibly')">
                <div class="flex gap-2 items-center">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            src="https://imgix.cosmicjs.com/4325e5a0-58eb-11ee-94c0-5f69598aa628-GambleResponsibly-OnDark.svg?auto=format,compress&w=20"
                            width="20" height="20" alt="icon" loading="lazy">
                    </div>
                    Gamble Responsibly
                </div>
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <div class="flex items-center text-sm text-white py-2 px-4 h-full"
                (click)="toggleNavMoreSidebar(); sendTapTag('Newsletter'); fireNewsletterPopUp();">
                <div class="flex gap-2 items-center">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            src="https://imgix.cosmicjs.com/432b15c0-58eb-11ee-94c0-5f69598aa628-Newsletter-OnDark.svg?auto=format,compress&w=20"
                            width="20" height="20" alt="icon" loading="lazy">
                    </div>
                    Join Newsletter
                </div>
            </div>
        </li>
        <li class="h-10 border-b border-gray-600">
            <div class="flex items-center text-sm text-white py-2 px-4 h-full"
                (click)="toggleNavMoreSidebar();toggleNavBettingSidebar()">
                <div class="flex gap-2 items-center">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            src="https://imgix.cosmicjs.com/353d1340-37fe-11ee-8ce3-27470be6bb93-Offers-OnDark.svg"
                            width="20" height="20" alt="icon" loading="lazy">
                    </div>
                    Betting
                </div>
            </div>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center text-sm text-white py-2 px-4 h-full"
                href="/sweepstakes-casinos" (click)="toggleNavMoreSidebar(); sendTapTag('Sweepstakes')">
                <div class="flex gap-2 items-center">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            src="https://imgix.cosmicjs.com/353d1340-37fe-11ee-8ce3-27470be6bb93-Offers-OnDark.svg"
                            width="20" height="20" alt="icon" loading="lazy">
                    </div>
                    Sweepstakes Casinos
                </div>
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center text-sm text-white py-2 px-4 h-full"
                href="/social-sportsbooks" (click)="toggleNavMoreSidebar(); sendTapTag('SocialSportsbooks')">
                <div class="flex gap-2 items-center">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            src="https://imgix.cosmicjs.com/353d1340-37fe-11ee-8ce3-27470be6bb93-Offers-OnDark.svg"
                            width="20" height="20" alt="icon" loading="lazy">
                    </div>
                    Social Sportsbooks
                </div>
            </a>
        </li>
        <!-- remove dimers shop cause its not runnning anymore -->
        <!-- <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center text-sm text-white py-2 px-4 h-full"
                href="https://shop.dimers.com/" target="_href" linkrel="noreferrer" (click)="sendTapTag('Dimers Shop')">
                <div class="flex gap-2 items-center">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            src="https://imgix.cosmicjs.com/432d38a0-58eb-11ee-94c0-5f69598aa628-DimersShop-OnDark.svg?auto=format,compress&w=20"
                            width="20" height="20" alt="icon" loading="lazy">
                    </div>
                    Dimers Shop
                </div>
            </a>
        </li> -->
        <!-- <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center justify-between text-sm text-white py-2 px-4 h-full"
                (click)="toggleSecondaryMenu(0)">
                <span> US Legal Betting </span>
                <fa-icon class="flex text-xs" [icon]="faChevronRight"></fa-icon>
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center justify-between text-sm text-white py-2 px-4 h-full"
                (click)="toggleSecondaryMenu(1)">
                <span> Sportsbooks Reviews</span>
                <fa-icon class="flex text-xs" [icon]="faChevronRight"></fa-icon>
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center justify-between text-sm text-white py-2 px-4 h-full"
                (click)="toggleSecondaryMenu(2)">
                <span> Sportsbooks Promos</span>
                <fa-icon class="flex text-xs" [icon]="faChevronRight"></fa-icon>
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center justify-between text-sm text-white py-2 px-4 h-full"
                (click)="toggleSecondaryMenu(3)">
                <span> Kentucky Betting</span>
                <fa-icon class="flex text-xs" [icon]="faChevronRight"></fa-icon>
            </a>
        </li> -->
    </ul>
</div>

<div class="nav-betting-sidebar overflow-y-auto" [ngClass]="this.navBettingSidebar ? 'show':'hide'">
    <ul class="list-none m-0">
        <li class="h-10 border-b border-gray-600">
            <div class="hover:text-white flex items-center justify-between text-sm text-white py-2 px-4 h-full"
                (click)="toggleSecondaryMenu(0)">
                <span> US Legal Betting </span>
                <fa-icon class="flex text-xs" [icon]="faChevronRight"></fa-icon>
            </div>
        </li>
        <li class="h-10 border-b border-gray-600">
            <div class="hover:text-white flex items-center justify-between text-sm text-white py-2 px-4 h-full"
                (click)="toggleSecondaryMenu(7)">
                <span> Canada Betting </span>
                <fa-icon class="flex text-xs" [icon]="faChevronRight"></fa-icon>
            </div>
        </li>
        <li class="h-10 border-b border-gray-600">
            <div class="hover:text-white flex items-center justify-between text-sm text-white py-2 px-4 h-full"
                (click)="toggleSecondaryMenu(1)">
                <span> Sportsbooks Reviews</span>
                <fa-icon class="flex text-xs" [icon]="faChevronRight"></fa-icon>
            </div>
        </li>
        <li class="h-10 border-b border-gray-600">
            <div class="hover:text-white flex items-center justify-between text-sm text-white py-2 px-4 h-full"
                (click)="toggleSecondaryMenu(6)">
                <span> DFS Reviews</span>
                <fa-icon class="flex text-xs" [icon]="faChevronRight"></fa-icon>
            </div>
        </li>
        <!-- <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center justify-between text-sm text-white py-2 px-4 h-full"
                (click)="toggleSecondaryMenu(2)">
                <span> DFS Reviews</span>
                <fa-icon class="flex text-xs" [icon]="faChevronRight"></fa-icon>
            </a>
        </li> -->
        <li class="h-10 border-b border-gray-600">
            <div class="hover:text-white flex items-center justify-between text-sm text-white py-2 px-4 h-full"
                (click)="toggleSecondaryMenu(2)">
                <span> Sportsbooks Promos</span>
                <fa-icon class="flex text-xs" [icon]="faChevronRight"></fa-icon>
            </div>
        </li>
        <!-- <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center justify-between text-sm text-white py-2 px-4 h-full"
                (click)="toggleSecondaryMenu(3)">
                <span> Vermont Betting</span>
                <fa-icon class="flex text-xs" [icon]="faChevronRight"></fa-icon>
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center justify-between text-sm text-white py-2 px-4 h-full"
                (click)="toggleSecondaryMenu(4)">
                <span> Kentucky Betting</span>
                <fa-icon class="flex text-xs" [icon]="faChevronRight"></fa-icon>
            </a>
        </li> -->
        <li class="h-10 border-b border-gray-600">
            <div class="hover:text-white flex items-center justify-between text-sm text-white py-2 px-4 h-full"
                (click)="toggleSecondaryMenu(5)">
                <span> North Carolina Betting</span>
                <fa-icon class="flex text-xs" [icon]="faChevronRight"></fa-icon>
            </div>
        </li>
    </ul>
</div>

<div class="pro-betting-sidebar overflow-y-auto" [ngClass]="navProSidebar ? 'show': 'hide'">
    <ul class="list-none m-0">
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center text-sm text-white py-2 px-4 h-full"
                routerLink="/subscription" (click)="toggleNavProSidebar()">
                <div class="flex gap-2 items-center">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg"
                            width="20" height="20" alt="icon" loading="lazy">
                    </div>
                    Dimers Pro
                </div>
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center text-sm text-white py-2 px-4 h-full"
            routerLink="/subscription/promotion/betmgm" (click)="toggleNavProSidebar()">
                <div class="flex gap-2 items-center">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg"
                            width="20" height="20" alt="icon" loading="lazy">
                    </div>
                    Get 3 Months Free
                </div>
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center text-sm text-white py-2 px-4 h-full"
                routerLink="/subscription/how-to-use-dimers-pro" (click)="toggleNavProSidebar();">
                <div class="flex gap-2 items-center">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg"
                            width="20" height="20" alt="icon" loading="lazy">
                    </div>
                    How To Use 
                </div>
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center text-sm text-white py-2 px-4 h-full"
                routerLink="/subscription/faq" (click)="toggleNavProSidebar();">
                <div class="flex gap-2 items-center">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg"
                            width="20" height="20" alt="icon" loading="lazy">
                    </div>
                    FAQ 
                </div>
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center text-sm text-white py-2 px-4 h-full"
               href="https://dimers.supahub.com/" target="_blank" (click)="toggleNavMoreSidebar()">
                <div class="flex gap-2 items-center">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg"
                            width="20" height="20" alt="icon" loading="lazy">
                    </div>
                    Suggest a Feature
                </div>
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center text-sm text-white py-2 px-4 h-full"
            routerLink="/subscription/affiliate" (click)="toggleNavMoreSidebar()">
                <div class="flex gap-2 items-center">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            src="https://cdn.ciphersports.io/assets/dimers-pro-icon.svg"
                            width="20" height="20" alt="icon" loading="lazy">
                    </div>
                    Become an Affiliate
                </div>
            </a>
        </li>

    </ul>
</div>


<div class="betting-sub-menu border-l border-gray-600" [ngClass]="this.secondaryMenu[0] ? 'show':'hide'">
    <ul class="list-none m-0">
        <li class="h-10 border-b border-gray-600" *ngFor="let item of states">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="{{ item.link }}"
                (click)="hideAllOpenMenuLinks(); sendTapTag( item.name +'Sports Betting')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        loading="lazy" [src]="item.img" height="20" width="20" alt="">
                </div>
                {{ item.name }}
            </a>
        </li>
        <li class="h-10 border-b border-t border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full" href="/best-books"
                (click)="hideAllOpenMenuLinks(); sendTapTag('US Betting Sites')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        src="https://cdn.cosmicjs.com/872b64b0-10bf-11ee-925e-ebfb5caceee1-United-states.svg" height="20"
                        width="20" alt="">
                </div>
                All Other States
            </a>
        </li>
    </ul>
</div>

<div class="sportsbooks-sub-menu border-l border-gray-600" [ngClass]="this.secondaryMenu[1] ? 'show':'hide'">
    <ul class="list-none m-0">
        <li class="h-10 border-b border-gray-600" *ngFor="let sportsbook of this.sportsbooks">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/best-books/reviews/{{sportsbook.metadata.sportsbook.slug}}"
                (click)="hideAllOpenMenuLinks(); sendTapTag(sportsbook.metadata.sportsbook.slug)">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="{{sportsbook.metadata.sportsbook.metadata.promos_small_logo.imgix_url}}?auto=format,compress&w=20">
                </div>
                {{sportsbook.metadata.sportsbook.title}}
            </a>
        </li>
         <!-- <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/best-books/reviews/bet365"
                (click)="hideAllOpenMenuLinks(); sendTapTag('bet365 sportsbook')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/98e6e380-4861-11ee-b0ba-f39b6f0c3501-Bet365-icon.svg?auto=format,compress&w=20">
                </div>
                bet365 
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/best-books/reviews/draftkings"
                (click)="hideAllOpenMenuLinks(); sendTapTag('DraftKings sportsbook')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/98e5f920-4861-11ee-89ab-17371fc03105-DraftKings-icon.svg?auto=format,compress&w=20">
                </div>
                DraftKings
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/best-books/reviews/betmgm"
                (click)="hideAllOpenMenuLinks(); sendTapTag('BetMGM sportsbook')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/9911c410-4861-11ee-89ab-17371fc03105-BetMGM-icon.svg?auto=format,compress&w=20">
                </div>
                BetMGM
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/best-books/reviews/fanduel"
                (click)="hideAllOpenMenuLinks(); sendTapTag('FanDuel sportsbook')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/98ac2470-4861-11ee-89ab-17371fc03105-FanDuelSportsbook-icon.svg?auto=format,compress&w=20">
                </div>
                FanDuel 
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/best-books/reviews/unibet"
                (click)="hideAllOpenMenuLinks(); sendTapTag('Unibet sportsbook')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/98e7f4f0-4861-11ee-b0ba-f39b6f0c3501-UniBet-icon.svg?auto=format,compress&w=20">
                </div>
                Unibet 
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/best-books/reviews/betrivers"
                (click)="hideAllOpenMenuLinks(); sendTapTag('BetRivers sportsbook')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/98acc0b0-4861-11ee-b0ba-f39b6f0c3501-BetRivers-icon.svg?auto=format,compress&w=20">
                </div>
                BetRivers 
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/best-books/reviews/wynnbet"
                (click)="hideAllOpenMenuLinks(); sendTapTag('WynnBET sportsbook')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/4095de80-10b6-11ee-925e-ebfb5caceee1-Wynnbet-Icon-Logo.png?auto=format,compress&w=20">
                </div>
                WynnBET 
            </a>
        </li> -->
    </ul>
</div>
<!-- <div class="dfs-sub-menu border-l border-gray-600" [ngClass]="this.secondaryMenu[2] ? 'show':'hide'">
    <ul class="list-none m-0">
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/dfs/reviews/ownersbox"
                (click)="hideAllOpenMenuLinks(); sendTapTag('OwnersBox review')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/d9f202b0-678d-11ee-bd7e-bf4f24fb39b1-OwnersBox-icon-1.png?auto=format,compress&w=20">
                </div>
                OwnersBox  
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/dfs/reviews/mojo"
                (click)="hideAllOpenMenuLinks(); sendTapTag('Mojo review')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/99123940-4861-11ee-b0ba-f39b6f0c3501-Mojo-icon.svg?auto=format,compress&w=20">
                </div>
                Mojo Fantasy 
            </a>
        </li>
    </ul>
</div> -->

<div class="sportsbook-promos-sub-menu border-l border-gray-600" [ngClass]="this.secondaryMenu[2] ? 'show':'hide'">
    <ul class="list-none m-0">
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/best-books/bonuses/bet365"
                (click)="hideAllOpenMenuLinks(); sendTapTag('bet365 promos')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/98e6e380-4861-11ee-b0ba-f39b6f0c3501-Bet365-icon.svg?auto=format,compress&w=20">
                </div>
                bet365 Promo
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/best-books/bonuses/fanduel"
                (click)="hideAllOpenMenuLinks(); sendTapTag('FanDuel promos')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/98ac2470-4861-11ee-89ab-17371fc03105-FanDuelSportsbook-icon.svg?auto=format,compress&w=20">
                </div>
                FanDuel Promo
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/best-books/bonuses/draftkings"
                (click)="hideAllOpenMenuLinks(); sendTapTag('DraftKings promos')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/98e5f920-4861-11ee-89ab-17371fc03105-DraftKings-icon.svg?auto=format,compress&w=20">
                </div>
                DraftKings Promo
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/best-books/bonuses/betmgm"
                (click)="hideAllOpenMenuLinks(); sendTapTag('BetMGM promos')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/9911c410-4861-11ee-89ab-17371fc03105-BetMGM-icon.svg?auto=format,compress&w=20">
                </div>
                BetMGM Promo 
            </a>
        </li>
    </ul>
</div>

<div class="ky-betting-sub-menu border-l border-gray-600" [ngClass]="this.secondaryMenu[4] ? 'show':'hide'">
    <ul class="list-none m-0">
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/best-books/kentucky/bonuses"
                (click)="hideAllOpenMenuLinks(); sendTapTag('Kentucky promos')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/44879fe0-196e-11ee-abcf-cf9ba95e6a41-Kentucky.svg?auto=format,compress&w=20">
                </div>
                Kentucky Promos 
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/best-books/kentucky/draftkings-promo-review"
                (click)="hideAllOpenMenuLinks(); sendTapTag('DraftKings KY Promo')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/44879fe0-196e-11ee-abcf-cf9ba95e6a41-Kentucky.svg?auto=format,compress&w=20">
                </div>
                DraftKings KY Promo 
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/best-books/kentucky/bet365-promo-review"
                (click)="hideAllOpenMenuLinks(); sendTapTag('bet365 KY Promo')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/44879fe0-196e-11ee-abcf-cf9ba95e6a41-Kentucky.svg?auto=format,compress&w=20">
                </div>
                bet365 KY Promo 
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/best-books/kentucky/fanduel-promo-review"
                (click)="hideAllOpenMenuLinks(); sendTapTag('FanDuel KY Promo')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/44879fe0-196e-11ee-abcf-cf9ba95e6a41-Kentucky.svg?auto=format,compress&w=20">
                </div>
                FanDuel KY Promo 
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/best-books/kentucky/betmgm-promo-review"
                (click)="hideAllOpenMenuLinks(); sendTapTag('BetMGM KY Promo')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/44879fe0-196e-11ee-abcf-cf9ba95e6a41-Kentucky.svg?auto=format,compress&w=20">
                </div>
                BetMGM KY Promo 
            </a>
        </li>
    </ul>
</div>
<div class="ky-betting-sub-menu border-l border-gray-600" [ngClass]="this.secondaryMenu[3] ? 'show':'hide'">
    <ul class="list-none m-0">
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/best-books/vermont/bonuses"
                (click)="hideAllOpenMenuLinks(); sendTapTag('vermont promos')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/c41ce290-9fad-11ee-93b6-d97d9d2a71dc-Vermont-map-icon.svg?auto=format,compress&w=20">
                </div>
                Vermont Promos 
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/best-books/vermont/draftkings-promo-review"
                (click)="hideAllOpenMenuLinks(); sendTapTag('DraftKings VT Promo')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/c41ce290-9fad-11ee-93b6-d97d9d2a71dc-Vermont-map-icon.svg?auto=format,compress&w=20">
                </div>
                DraftKings VT Promo 
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/best-books/vermont/fanduel-promo-review"
                (click)="hideAllOpenMenuLinks(); sendTapTag('FanDuel VT Promo')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/c41ce290-9fad-11ee-93b6-d97d9d2a71dc-Vermont-map-icon.svg?auto=format,compress&w=20">
                </div>
                FanDuel VT Promo 
            </a>
        </li>
    </ul>
</div>
<div class="ky-betting-sub-menu border-l border-gray-600" [ngClass]="this.secondaryMenu[5] ? 'show':'hide'">
    <ul class="list-none m-0">
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/best-books/north-carolina/bonuses"
                (click)="hideAllOpenMenuLinks(); sendTapTag('north carolina promos')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/5fed4c60-115a-11ee-abb6-f9903c690bdf-North-Carolina.svg?auto=format,compress&w=20">
                </div>
                North Carolina Promos 
            </a>
        </li>
        <!-- <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/best-books/north-carolina/bet365-promo-review"
                (click)="hideAllOpenMenuLinks(); sendTapTag('bet365 NC Promo')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/5fed4c60-115a-11ee-abb6-f9903c690bdf-North-Carolina.svg?auto=format,compress&w=20">
                </div>
                bet365 NC Promo 
            </a>
        </li> -->
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/best-books/north-carolina/espn-bet-promo-review"
                (click)="hideAllOpenMenuLinks(); sendTapTag('ESPN Bet NC Promo')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/5fed4c60-115a-11ee-abb6-f9903c690bdf-North-Carolina.svg?auto=format,compress&w=20">
                    ESPN Bet NC Promo
                </div>
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/best-books/north-carolina/fanatics-promo-review"
                (click)="hideAllOpenMenuLinks(); sendTapTag('Fanatics NC Promo')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/5fed4c60-115a-11ee-abb6-f9903c690bdf-North-Carolina.svg?auto=format,compress&w=20">
                </div>
                Fanatics NC Promo 
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/best-books/north-carolina/underdog-promo-review"
                (click)="hideAllOpenMenuLinks(); sendTapTag('Underdog NC Promo')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/5fed4c60-115a-11ee-abb6-f9903c690bdf-North-Carolina.svg?auto=format,compress&w=20">
                </div>
                Underdog NC Promo 
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/best-books/north-carolina/betmgm-promo-review"
                (click)="hideAllOpenMenuLinks(); sendTapTag('BetMGM NC Promo')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/5fed4c60-115a-11ee-abb6-f9903c690bdf-North-Carolina.svg?auto=format,compress&w=20">
                </div>
                BetMGM NC Promo 
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/best-books/north-carolina/draftkings-promo-review"
                (click)="hideAllOpenMenuLinks(); sendTapTag('DraftKings NC Promo')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/5fed4c60-115a-11ee-abb6-f9903c690bdf-North-Carolina.svg?auto=format,compress&w=20">
                </div>
                DraftKings NC Promo 
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/best-books/north-carolina/fanduel-promo-review"
                (click)="hideAllOpenMenuLinks(); sendTapTag('FanDuel NC Promo')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/5fed4c60-115a-11ee-abb6-f9903c690bdf-North-Carolina.svg?auto=format,compress&w=20">
                </div>
                FanDuel NC Promo 
            </a>
        </li>
    </ul>
</div>
<div class="dfs-sub-menu border-l border-gray-600" [ngClass]="this.secondaryMenu[6] ? 'show':'hide'">
    <ul class="list-none m-0">
        <ng-container *ngFor="let item of dfsProviders">
            <li class="h-10 border-b border-gray-600">
                <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                    href="/dfs/reviews/{{item.metadata.sportsbook.slug}}"
                    (click)="hideAllOpenMenuLinks(); sendTapTag('Mojo review')">
                    <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                        <img *ngIf="navInteracted"
                            height="20" width="20" alt="logo" loading="lazy"
                            src="{{item.metadata.sportsbook.metadata.promos_small_logo.imgix_url}}?auto=format,compress&w=20">
                    </div>
                    {{item.metadata.sportsbook.title}}
                </a>
            </li>
        </ng-container>
    </ul>
</div>
<div class="dfs-sub-menu border-l border-gray-600" [ngClass]="this.secondaryMenu[7] ? 'show':'hide'">
    <ul class="list-none m-0">
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/ca/betting/ontario"
                (click)="hideAllOpenMenuLinks(); sendTapTag('ontario bestbooks')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/1945bfe0-0c3b-11ef-911e-9f1d49670742-Ontario-white.svg?auto=compress,enhance,format&w=20">
                </div>
                Ontario 
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/ca/betting/reviews/draftkings-canada"
                (click)="hideAllOpenMenuLinks(); sendTapTag('DraftKings ontario reviews')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/98e5f920-4861-11ee-89ab-17371fc03105-DraftKings-icon.svg?auto=compress,enhance,format&w=20">
                </div>
                DraftKings Ontario  
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/ca/betting/reviews/betmgm-canada"
                (click)="hideAllOpenMenuLinks(); sendTapTag('BetMGM ontario reviews')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/9911c410-4861-11ee-89ab-17371fc03105-BetMGM-icon.svg?auto=compress,enhance,format&w=20">
                </div>
                BetMGM Ontario 
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/ca/betting/reviews/betano"
                (click)="hideAllOpenMenuLinks(); sendTapTag('Betano ontario reviews')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/dd4755a0-be68-11ee-8229-0f62eab2dd82-Betano-icon.png?auto=compress,enhance,format&w=20">
                </div>
                Betano Ontario   
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/ca/betting/reviews/betway"
                (click)="hideAllOpenMenuLinks(); sendTapTag('Betway ontario reviews')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy" class="rounded-sm overflow-hidden"
                        src="https://imgix.cosmicjs.com/6b14fe80-0c36-11ef-911e-9f1d49670742-Betway-small-1.svg?auto=compress,enhance,format&w=20">
                </div>
                Betway Ontario  
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/ca/betting/reviews/pinnacle"
                (click)="hideAllOpenMenuLinks(); sendTapTag('Pinnacle ontario reviews')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy" class="rounded-sm overflow-hidden"
                        src="https://imgix.cosmicjs.com/644f4a60-0c36-11ef-911e-9f1d49670742-Pinacle-small-1.svg?auto=compress,enhance,format&w=20">
                </div>
                Pinnacle Ontario 
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/ca/betting/reviews/bet365-canada"
                (click)="hideAllOpenMenuLinks(); sendTapTag('Bet365 ontario reviews')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/98e6e380-4861-11ee-b0ba-f39b6f0c3501-Bet365-icon.svg?auto=compress,enhance,format&w=20">
                </div>
                Bet365 Ontario 
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/ca/betting/reviews/pointsbet-canada"
                (click)="hideAllOpenMenuLinks(); sendTapTag('Bet365 ontario reviews')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/98ab8830-4861-11ee-89ab-17371fc03105-PointsBet-icon.svg?auto=compress,enhance,format&w=20">
                </div>
                PointsBet Ontario
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/ca/betting/reviews/betvictor-canada"
                (click)="hideAllOpenMenuLinks(); sendTapTag('BetVictor Ontario reviews')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/ca8eaaf0-455c-11ef-8f56-adb81d8aea77-BetVictor-Small-White-2.svg?auto=compress,enhance,format&w=20">
                </div>
                BetVictor Ontario
            </a>
        </li>
        <li class="min-h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/ca/betting/reviews/sports-interaction"
                (click)="hideAllOpenMenuLinks(); sendTapTag('Sports Interaction Ontario reviews')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/20e2df50-4dc5-11ef-926f-211de7a312da-Copy-of-SILogoStackedSmallColour.png?auto=compress,enhance,format&w=20">
                </div>
                Sports Interaction Ontario
            </a>
        </li>
        <li class="min-h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/ca/betting/reviews/powerplay-canada"
                (click)="hideAllOpenMenuLinks(); sendTapTag('PowerPlay Ontario reviews')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/451aba80-6339-11ef-b164-a75e20f7a9e0-PowerPlay-logo.svg?auto=compress,enhance,format&w=20">
                </div>
                PowerPlay Ontario
            </a>
        </li>
        <li class="h-10 border-b border-gray-600">
            <a class="hover:text-white flex items-center gap-2 text-sm text-white py-2 px-4 h-full"
                href="/ca/betting/reviews/888-canada"
                (click)="hideAllOpenMenuLinks(); sendTapTag('888 Ontario reviews')">
                <div class="icon-ctr h-5 w-5 flex flex-col justify-center items-center">
                    <img *ngIf="navInteracted"
                        height="20" width="20" alt="logo" loading="lazy"
                        src="https://imgix.cosmicjs.com/8e281420-5e84-11ef-86e1-b75943817551-888casino-small.png?auto=compress,enhance,format&w=20">
                </div>
                888 Ontario
            </a>
        </li>
    </ul>
</div>

<div id="mask" [class.nav-sidebar-mask]="this.navBettingSidebar" (click)="this.hideNavBettingSidebar()"></div>