<nav>
	<app-site-header
		[fireNewsletterPopUp]="fireNewsletterPopUp"
		[showScore]="showScore"
        [showMobileOffers]="showMobileOffers"
	></app-site-header>
</nav>
<main>
	<router-outlet></router-outlet>
</main>
<!-- <div class="update-prompt" [class.on-screen]="updatePromptVisible" (click)="reloadPage()">
	<div class="update-copy">
		New Data Available
	</div>
	<div class="update-button">
		<fa-icon [icon]="faRedoAlt" aria-hidden="true"></fa-icon>
		Reload
	</div>
</div> -->
<app-site-footer
	[fireNewsletterPopUp]="fireNewsletterPopUp"
	[showPartner]="showPartner"
></app-site-footer>
<app-subscribe-click-prompt
	[visible]="newsletterPopUpVisible"
	[hideCallback]="hideNewsletterPopUp"
></app-subscribe-click-prompt>
<app-non-legal-modal></app-non-legal-modal>
<app-pro-modal></app-pro-modal>
<app-feedback-form></app-feedback-form>
<!-- <app-tags-notification-popup></app-tags-notification-popup> -->
<app-exit-intent-popup></app-exit-intent-popup>
<!-- <app-subscribe-scroll-prompt></app-subscribe-scroll-prompt> -->
<!-- <app-exit-prompt></app-exit-prompt> -->