<div class="rounded-lg shadow overflow-hidden flex flex-col offer-card cursor-pointer" 
    (click)="redirectBookiePromoLink(
        offer.metadata.promos_link,
        {src: pageLocation, promo: 'true'},
        offer.metadata.sportsbook[0]
    )">
    <div class="px-4 flex items-center justify-center h-16"
        [style.background-color]="offer?.metadata.sportsbook[0].metadata.hex_color">
        <img alt="logo" draggable="false" [src]="offer?.metadata.sportsbook[0].metadata.dark_bg_logo.imgix_url + '?auto=format,compress&h=40'" class="h-10 w-[108px]"
            loading="lazy">
    </div>
    <div class="px-4 pt-4 flex flex-col gap-2">
        <div class="bg-gray-100  p-3 rounded flex flex-col gap-2">
            <div class="flex flex-col gap-2 justify-between offer-content">
                <div class="flex items-center justify-center gap-3 flex-wrap" *ngIf="offer?.metadata?.tags">
                    <ng-container *ngFor="let tag of offer?.metadata?.tags">
                        <div class="rounded-xl px-2 uppercase text-[12px] font-medium"
                            [style.background-color]="tag.metadata.bg_hex_color"
                            [style.color]="tag.metadata.text_hex_color">
                            {{ tag.metadata.text}}
                        </div>
                    </ng-container>
                </div>
                <div class="text-highlight-100 text-base font-bold text-center header leading-5">
                    {{ offer.metadata.promos_title }}
                </div>
                <div class="text-xs text-neutral-700 text-center">
                    T&C's apply.
                </div>
            </div>
            <button class="claim-button">
                {{ offer.metadata.promos_button_text }}
            </button>
        </div>
        <div (click)="toggleOfferDropdown($event, offer.metadata.sportsbook[0].slug)" class="pb-2">
            <div class="flex justify-center items-center cursor-pointer">
                <div class="text-sm font-medium text-highlight-100" *ngIf="reviewLink">
                    <a class="text-highlight-100" [href]="reviewLink">
                        Read review
                    </a>
                </div>
                <div class="cursor-pointer ml-auto">
                    <ng-container *ngIf="stateCode !== 'ontario'">
                        <span class="text-sm font-medium text-highlight-100 mr-2">How to claim</span>
                    </ng-container>
                    <ng-container *ngIf="stateCode === 'ontario'">
                        <span class="text-sm font-medium text-highlight-100 mr-2">Find more info</span>
                    </ng-container>
                    <fa-icon class="text-xs text-highlight-100" *ngIf="!offerDropdownOpen" [icon]="faChevronDown"></fa-icon>
                    <fa-icon class="text-xs text-highlight-100" *ngIf="offerDropdownOpen" [icon]="faChevronUp"></fa-icon>
                </div>
            </div>
            <div class="mt-2 flex flex-col gap-2" *ngIf="offerDropdownOpen">
                <div class="promo-steps text-xs font-semibold leading-[18px]" *ngIf="offer?.metadata.promos_steps"
                    [innerHTML]="offer?.metadata.promos_steps | trustCosmic"></div>
                <div class="text-xs p-4 bg-slate-100 -ml-4 -mr-4 -mb-4 disclaimer" *ngIf="offerDropdownOpen"
                [innerHTML]="offer?.metadata.promos_disclaimer | trustCosmic">
                </div>
            </div>
        </div>
    </div>
</div>