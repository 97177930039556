import { Component, Input, OnInit } from '@angular/core';
import { GoogleTagManagerService } from 'src/app/angular-google-tag-manager.service';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';
import { NgIf, NgFor } from '@angular/common';
import { TrustCosmicPipe } from '../trust-cosmic.pipe';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-best-offer-card',
    templateUrl: './best-offer-card.component.html',
    styleUrls: ['./best-offer-card.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, RouterLink, FontAwesomeModule, TrustCosmicPipe]
})
export class BestOfferCardComponent implements OnInit {

    @Input() offer: any;
    @Input() pageLocation?: string;
    @Input() stateCode?:string;

    faChevronDown = faChevronDown;
    faChevronUp = faChevronUp;
    offerDropdownOpen: boolean = false;

    browserMode = false;
    reviewLink:string;           

    constructor(
        private gtmService: GoogleTagManagerService,
    ) {}

    ngOnInit() {
        if (this.offer.metadata?.sportsbook[0]?.metadata?.review?.id) {
            this.reviewLink = `/best-books/reviews/${this.offer.metadata?.sportsbook[0].slug}`;
        }
        
        if (this.offer.metadata?.sportsbook[0]?.metadata?.dfs_review?.id) {
            this.reviewLink = `/dfs/reviews/${this.offer.metadata?.sportsbook[0].slug}`;
        }
        
        if (this.offer.metadata?.sportsbook[0]?.metadata?.canadian_reviews?.id) {
            this.reviewLink = `/ca/betting/reviews/${this.offer.metadata?.sportsbook[0].slug}`;
        }
    }

    redirectBookiePromoLink(url: string, queryParams: Record<string, string>, bookmaker: any) {
        // if (!this.excludeBookies(bookmaker?.slug || "")) {
        //     queryParams.c = (this.router.url.split("/")[1] || 'home') + (
        //         this.authHelper.user
        //             ? ('|' + this.authHelper.user.id)
        //             : '|visitor'
        //     );
        // }

        if (bookmaker.slug === 'underdog-fantasy' || bookmaker.slug === 'pointsbet-canada') {
            delete queryParams['promo']
        }        
        const combinedUrl = url + (url.includes("?") ? "&" : "?") + new URLSearchParams(queryParams).toString();

        if (typeof window !== "undefined") {
            const gtmTag = {
                event: "exit",
                event_category: "welcome",
                event_label: bookmaker.slug,
                event_location: this.pageLocation
            };

            this.gtmService.pushTag(gtmTag)
                .then(() => {
                    window.open(combinedUrl, "_self");
                })
                .catch(e => {
                    console.error("Google Tag Manager Script failed: " + e)
                    window.open(combinedUrl, "_self");
                });
        }
    }

    toggleOfferDropdown(event, slug:string): void {
        event.stopPropagation();
        this.offerDropdownOpen = !this.offerDropdownOpen;
    }
}
