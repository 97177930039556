import { Tag } from "./tag.model";

export const TAGS: Array<Tag> = [
    {
        key:"favorite",
        name:"Favorite",
        image: "https://imgix.cosmicjs.com/e11ddb30-b35f-11ef-8557-952820089831-Favorite-7.png?auto=compress,enhance,format&w=100",
        type: "best bet",
        condition: (bet) =>
        "type" in bet &&
        bet.type === 'h2h' &&
        bet.edge > 0 &&
        (
            (bet.odds <= 1.65 && bet.match?.MatchData?.Sport !== 'CBB' && bet.match?.MatchData?.Sport !== 'CFB') || 
            (bet.odds <= 1.25 && (bet.match?.MatchData?.Sport === 'CBB' || bet.match?.MatchData?.Sport === 'CFB'))
        ),
    },{
        key:"underdog",
        name:"Underdog",
        image: "https://imgix.cosmicjs.com/2e3f0c10-b2a0-11ef-8557-952820089831-Underdog-3.png?auto=compress,enhance,format&w=100",
        type: "best bet",
        condition: (bet)=>
        "type" in bet &&
        bet.type === 'h2h' &&
        bet.edge > 0 &&
        (
            (bet.odds >= 2.6 && (bet.match?.MatchData?.Sport !== 'CBB' && bet.match?.MatchData?.Sport !== 'CFB')) ||
            (bet.odds >= 5 && (bet.match?.MatchData?.Sport === 'CBB' || bet.match?.MatchData?.Sport === 'CFB'))
        ),

    },{
        key:"tuddy-threat",
        name:"Tuddy Threat",
        image:"https://imgix.cosmicjs.com/cc8c8410-ac65-11ef-b834-3f4309ee44b7-Tuddy-Threat-v3.png?auto=compress,enhance,format&w=100",
        sports: ["NFL", "CFB"],
        type:"best prop",
        condition: (bet) =>
            "prop_name" in bet &&
            bet.prop_name.toLowerCase() === "score a touchdown" &&
            bet.probability > 0.5,
    },{
        key:"sharp-shooter",
        name:"Sharp Shooter",
        image:"https://imgix.cosmicjs.com/9aeba6c0-b2be-11ef-8557-952820089831-Sharp-Shooters-4.png?auto=compress,enhance,format&w=100",
        sports: ["WNBA", "NBA", "CBB"],
        type:"best prop",
        condition: (bet) => 
            "prop_name" in bet &&
            bet.prop_name?.toLowerCase() === "points scored" &&
            bet.prop_text?.toLowerCase().includes("over") &&
            (
              (bet?.projection >= 20 && bet.Sport === "NBA") ||
              (bet?.projection >= 15 && (bet.Sport === "CBB" || bet.Sport === "WNBA"))
            ),
    },{
        key:"rain-maker",
        name:"Rain Maker",
        image:"https://imgix.cosmicjs.com/c69933c0-ac68-11ef-b834-3f4309ee44b7-Rain-Maker-v3.png?auto=compress,enhance,format&w=100",
        sports: ["WNBA", "NBA", "CBB"],
        type:"best prop",
        condition: (bet) =>
            "prop_name" in bet &&
            bet.prop_name.toLowerCase() === '3-pointers made' &&
            bet.prop_text.toLowerCase().includes('over') &&
            bet?.projection >= 3
    },{
        key:"board-crasher",
        name:"Board Crasher",
        image:"https://imgix.cosmicjs.com/fc61c5a0-ac4d-11ef-a0a4-63c6ac3894c0-Board-Crasher-v3.png?auto=compress,enhance,format&w=100",
        sports: ["WNBA", "NBA", "CBB"],
        type:"best prop",
        condition: (bet) =>
            "prop_name" in bet &&
            bet.prop_name.toLowerCase() === "total rebounds" &&
            bet.prop_text.toLowerCase().includes("over") &&
            bet?.projection >= 6,
    },{
        key:"dime-dropper",
        name:"Dime Dropper",
        image:"https://imgix.cosmicjs.com/6c8fa790-ac5b-11ef-b834-3f4309ee44b7-Dime-Dropper-v3.png?auto=compress,enhance,format&w=100",
        sports: ["WNBA", "NBA", "CBB"],
        type:"best prop",
        condition: (bet) =>
            "prop_name" in bet &&
            bet.prop_name.toLowerCase() === "total assists" &&
            bet.prop_text.toLowerCase().includes("over") &&
            bet?.projection >= 6,
    },{
        key:"slugger",
        name:"Slugger",
        image:"https://imgix.cosmicjs.com/65c913b0-ae09-11ef-94ac-8b1d731b4a2b-Slugger-v4.png?auto=compress,enhance,format&w=100",
        sports: ["MLB"],
        type:"best prop",
        condition: (bet) =>
            "prop_name" in bet &&
            bet.prop_name.toLowerCase() === "to get a hr" &&
            bet.probability > 0.22 &&
            bet.edge > 5,
    }
];
